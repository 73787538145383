



























import Vue from 'vue';
import sha256 from 'crypto-js/sha256';

import RoundedButton from '@/components/buttons/RoundedButton.vue';

import { generateKey } from '@/helpers/StringGenerator';

interface AppFeature {
  id: number;
  title: string;
}

export default Vue.extend({
  name: 'Start',
  components: {
    RoundedButton,
  },
  computed: {
    features(): AppFeature[] {
      return [
        { id: 1, title: 'Quick control for your buildings', },
        { id: 2, title: 'Add your favorite scenes', },
      ];
    },
  },
  methods: {
    async requestAuth() {
      const host = process.env.VUE_APP_WIZ_PRO_DASHBOARD_HOST;
      const clientId = process.env.VUE_APP_WIZ_PRO_CLIENT_ID;
      const codeChallenge = this.generateCodeChallenge();
      const codeChallengeMethod = 'S256';
      const websiteUrl = `${host}/#/oauth/authorize`
        + `?client_id=${clientId}`
        + `&code_challenge=${codeChallenge}`
        + `&code_challenge_method=${codeChallengeMethod}`;
      window.open(websiteUrl, "_self");
    },
    generateCodeChallenge(): string {
      const codeVerifier = generateKey();
      this.$store.commit('setCodeVerifier', codeVerifier);
      const codeChallenge = sha256(codeVerifier).toString();
      return codeChallenge;
    },
  },
});
